<template>
  <div class="me" :style="controlStyle" @click.stop.prevent="onClick">
    <!-- table table-condensed table-bordered table-hover dataTable table-striped table-responsive -->
    <template v-for="(page, ip) in pages">
      <!-- <h1 class="page-break" :key="`nextpage${ip}`">page</h1> -->
      <table
        :id="tableId"
        ref="table"
        class="table display-table"
        :key="`page${ip}`"
        :style="[styleCfg.cssVars, {'margin-bottom': `${page.spacing}px`}]"
        :class="{
          ...styleCfg.classes,
          ...{
            'print-preview': printPreview
          }
        }"
        @mousedown.exact="onMouseDown($event)"
        @dragenter.stop.prevent
        @dragover.stop.prevent
      >
        <tbody ref="sheet" @dragover="dragOverColumn($event)">
          <tr v-for="(row, ir) in page.rows" :key="ir">
            <td
              v-for="(col, ic) in row"
              :data-cell="`${page.offset},${ir},${ic}`"
              :key="ic"
              :style="cellStyle(page.offset, ir, ic)"
              :class="cellClass(page.offset + ir, ic)"
              @click.stop.prevent="onCellClicked(page.offset + ir, ic)"
              @contextmenu.stop.prevent="
                onCellMenuClicked($event, page.offset + ir, ic, null)
              "
            >
              <div
                tabindex="0"
                class="cell"
                :style="cellContentStyle(page.offset + ir, ic)"
                @keyup="onKeyUp($event)"
              >
                <CellInput
                  v-if="allowInput(page.offset + ir, ic)"
                  :value="values[normalizeRow(ir, page)][ic]"
                  @input="setCellValue(page.offset + ir, ic, $event)"
                  @contextmenu.stop.prevent="
                    onCellMenuClicked($event, page.offset + ir, ic, null)
                  "
                  @keyup.delete.stop.prevent="onKeyUp"
                  @click.stop.prevent="onCellClicked(page.offset + ir, ic)"
                />
                <span
                  v-else-if="inlineEditor"
                  @contextmenu.stop.prevent="
                    onCellMenuClicked($event, page.offset + ir, ic, null)
                  "
                  @click.stop.prevent="onCellClicked(page.offset + ir, ic)"
                  >{{ values[normalizeRow(ir, page)][ic] }}&nbsp;
                </span>
                <span v-else>
                  {{ values[normalizeRow(ir, page)][ic] }}&nbsp;
                </span>
              </div>
              <div
                draggable="true"
                class="cell-separator"
                :class="{
                  'column-dragging': dragging.c == ic
                }"
                :style="{
                  right: `${-1 * (dragging.c == ic ? dragging.w : 0)}px`
                }"
                v-if="isEditing && ic < row.length - 1"
                @mousedown.exact="onMouseDownDragColumn($event, ir, ic)"
                @mouseup.exact="onMouseUpDragColumn($event, ir, ic)"
                @dragstart="startDragColumn($event, ir, ic)"
                @dragend="stopDragColumn($event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div style="page-break-before: always;" :key="`nextpage${ip}`"></div> -->
    </template>
    <portal to="contextmenu" v-if="isEditing">
      <vue-context
        ref="menu"
        class="contextmenu"
        v-if="isEditing"
        @open="onContextMenu()"
        :closeOnScroll="false"
        :useScrollHeight="false"
        :heightOffset="menu.oy"
      >
        <!-- :closeOnScroll="cmCloseOnScroll" -->
        <template>
          <li
            class="contextmenu-info address"
            @click.stop.prevent="
              onCellMenuClicked($event, null, null, 'cell:edit')
            "
          >
            {{ a1 }} <i class="fa fa-pencil"></i>
          </li>
          <li
            class="contextmenu-info close"
            @click.stop.prevent="$refs.menu && $refs.menu.close()"
          >
            <i class="fa fa-close"></i>
          </li>
          <li class="gap"></li>
          <template v-for="(menuItem, io) in cellMenu">
            <li v-if="menuItem.title == '-'" class="divider" :key="io"></li>
            <li v-else :key="io">
              <a
                class="has-shortcut"
                @click.stop.prevent="
                  onCellMenuClicked($event, null, null, menuItem.option)
                "
              >
                {{ $t(menuItem.title) }}
              </a>
            </li>
          </template>
        </template>
      </vue-context>
    </portal>
    <div style="position: relative; display: block" v-if="isEditing">
      <!-- {{ dataset }} -->
    </div>
  </div>
</template>

<script>
import VueContext from "@/plugins/vue-context";
import CellInput from "@/components/widgets/cell-input.vue";
import {currentValueTypeCast} from "@/services/equipment-data.js";
export default {
  name: "SynopticSimpleTable",
  props: {
    control: {
      type: [Array, Object],
      required: false,
      default: () => null
    },
    inlineEditor: {
      type: Boolean,
      default: true,
      required: false
    },
    standAlone: {
      // true - it triggers edit events by itself / false - the parent component does.
      type: Boolean,
      default: true,
      required: false
    },
    offsetTop: {
      type: Number,
      required: false,
      default: null
    },
    currentRect: {
      type: Object,
      required: false,
      default: () => null
    },
    datasetIdList: {
      type: Array,
      required: false,
      default: () => []
    },
    dataTable: {
      type: Array,
      required: false,
      default: () => null
    },
    namedQuery: {
      type: String,
      required: false,
      default: ""
    }
  },
  inject: {
    pageSettings: {
      from: "pageSettings",
      default: null
    }
  },
  components: {
    CellInput,
    VueContext
  },
  data() {
    return {
      active: {r: -1, c: -1},
      cellMenu: [
        {title: "rich_text.add_row_before", option: "row:add_above"},
        {title: "rich_text.add_row_after", option: "row:add_below"},
        {title: "rich_text.delete_row", option: "row:del"},
        {title: "rich_text.reset_row_style", option: "row:reset_style"},
        {title: "rich_text.reset_row_content", option: "row:reset_content"},
        {title: "-"},
        {title: "rich_text.add_column_before", option: "column:add_left"},
        {title: "rich_text.add_column_after", option: "column:add_right"},
        {title: "rich_text.delete_column", option: "column:del"},
        {title: "rich_text.reset_column", option: "column:reset"},
        {title: "-"},
        {title: "rich_text.reset_cell", option: "cell:reset"}
      ],
      cellInput: false,
      errorCheck: 0,
      menu: {sx: 0, sy: 0, oy: 0},
      rowHeight: null,
      tableId: "",
      dragging: {r: -1, c: -1, w: -1},
      cellFormEditor: false
    };
  },
  computed: {
    printScale() {
      return this?.pageSettings?.scale ?? 1;
    },
    pageHeight() {
      return (
        (this?.pageSettings?.orientation == "p"
          ? this?.pageSettings?.height
          : this?.pageSettings?.width) ?? 0
      );
    },
    pagePadding() {
      return this?.pageSettings?.padding ?? 0;
    },
    values() {
      // output sheet (already formatted cells)
      let entry = null,
        vlr = "";
      return (this?.labeledSheet?.rows || []).map((r, ir) =>
        r.map((c, ic) => {
          entry = this.dataAt(ir, ic);
          vlr =
            entry !== null
              ? this.$root.$formatter.format(entry)
              : this.value(ir, ic);
          return vlr === "" && entry && entry.default ? entry.default : vlr;
        })
      );
    },
    sheet() {
      // Control property:
      // - While legacy Synoptic control (messer), it requires a dataSheet attribute
      // - While Tablepanel control is meant to be the sheet configuration (new)

      // const ret = this?.dataset?.length
      //   ? this.values
      //   : this?.control?.dataSheet ||
      //     this?.control?.synopticComponent?.sheet || [[{ value: "" }]];

      return (
        this.dataTable ||
        this?.control?.dataSheet ||
        this?.control?.synopticComponent?.sheet || [[{value: ""}]]
      );
    },
    labeledSheet() {
      if (!this.pageHeight || !this._isMounted || !this.dataTable)
        return {rows: this.sheet, offset: [0]};
      let pageHeight = this.pageHeight - this.pagePadding;
      let heightInFirstPage = pageHeight - this.offsetTop;

      let nItemsInFirstPage =
        (this.$attrs.nItemsInFirstPage ?? 0) ||
        Math.floor(heightInFirstPage / this.rowHeight) - 1;

      let nItemsInRemainingPages =
        (this.$attrs.nItemsInRemainingPages ?? 0) ||
        Math.floor(pageHeight / this.rowHeight) - 1;

      nItemsInFirstPage = nItemsInFirstPage < 2 ? 2 : nItemsInFirstPage;
      nItemsInRemainingPages =
        nItemsInRemainingPages < 2 ? 2 : nItemsInRemainingPages;

      let offset = [0];
      let rows = this.sheet.map((r) => [...r]);
      // get the custom rows used as header that preceeds the dataset target
      let headerRows = [...rows.slice(0, this.datasetTarget?.r ?? 0)];
      // inserts header for second page
      let index = nItemsInFirstPage;
      if (rows.length > index) {
        rows.splice(index, 0, ...headerRows);
        offset.push(index);
      }
      // inserts header for other pages (from the third onwards)
      for (
        index = nItemsInFirstPage + nItemsInRemainingPages;
        index < rows.length;
        index += nItemsInRemainingPages - headerRows.length + 1
      ) {
        offset.push(index);
        rows.splice(index, 0, ...headerRows);
      }
      return {rows: rows, offset: offset};
    },
    pages() {
      if (!(this.sheet || []).length)
        return [
          {
            offset: 0,
            rows: []
          }
        ];
      if (!this.rowHeight || !this.dataTable)
        return [
          {
            offset: 0,
            rows: this.labeledSheet.rows
          }
        ];

      let pageHeight = this.pageHeight - this.pagePadding;
      let pages = [];
      let offsetTop = (this.offsetTop || 0) - 5;
      let rows = [];
      for (var i = 0; i < this.labeledSheet.offset.length; i++) {
        rows = this.labeledSheet.rows.slice(
          this.labeledSheet.offset[i],
          this.labeledSheet.offset[i + 1]
        );
        let spacing = 0;
        if (
          this.labeledSheet.offset.length > 1 &&
          i != this.labeledSheet.offset.length - 1
        ) {
          spacing =
            (pageHeight - offsetTop - this.rowHeight * rows.length) /
            this.printScale;
        }
        pages.push({
          spacing: Math.floor(spacing),
          offset: this.labeledSheet.offset[i],
          rows: rows
        });
        offsetTop = -5;
      }
      return pages;
    },
    fields() {
      var self = this;
      var sheet = self.sheet;
      var ret = sheet.map(function(row) {
        return {
          data_id: row[1].data_id,
          decimals: 0,
          default: 0,
          format: row[1].format,
          label: row[0].value,
          refreshInterval: 0,
          selected: true
        };
      });
      return ret;
    },
    selected() {
      var self = this;
      var sheet = self.sheet;
      var lst = sheet.map(function(r) {
        return r[1].data_id;
      });
      return lst;
    },
    nColumns() {
      let max = 0;
      if (this.sheet) {
        this.sheet.forEach((row) => {
          max = row.length > max ? row.length : max;
        });
      }
      return max;
    },
    nRows() {
      return this?.sheet?.length || 0;
    },
    size() {
      return {rows: this.nRows, cols: this.nColumns};
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    controlStyle() {
      let style = {
        ...(this?.control?.synopticComponent?.style || {}),
        transform: `rotate(${parseInt(
          this?.control?.synopticComponent?.rotation || 0
        )}deg)`
      };
      if (this.currentRect) {
        style.width = this.currentRect.width + "px";
        style.height = this.currentRect.height + "px";
      }
      if (this.isEditing) {
        style["z-index"] = "9";
      }
      if (this.errorCheck && this.rectError()) {
        style["overflow"] = "hidden";
      }
      if (this.tmp && this.tmp.style) {
        Object.assign(style, this.tmp.style);
      }
      return style;
    },
    tableStyle() {
      let entry = JSON.parse(
        JSON.stringify(this?.control?.synopticComponent?.tableStyle || null)
      );
      if (entry) {
        if (!entry.classes["table-bordered"]) {
          entry.cssVars["--border-width"] = 0;
        }
        if (this.errorCheck && this.rectError()) {
          entry.classes["table-error"] = true;
        }
        entry.cssVars["--text-size-adjust"] = this.$utils.iOS() ? "35%" : "70%";
      }

      return entry;
    },
    styleCfg() {
      let style = this.tableStyle || {
        classes: {
          "table-bordered": true,
          "table-condensed": true,
          "table-striped": true,
          "table-hover": true
        },
        cssVars: {
          "--border-width": "1px", // must be 0 if no bordered
          "--border-style": "solid",
          "--border-color": "gray",
          "--text-size-adjust": this.$utils.iOS() ? "35%" : "70%"
        }
      };
      return style;
    },
    datasetTarget() {
      // convert A1 notation to {c:0, r:0} (target cell)
      let target = null;
      if (this?.control?.synopticComponent?.sheet) {
        let cfg = this?.control?.synopticComponent?.dataSetConfig || {};
        if (cfg?.address) {
          let c = parseInt(cfg.address.charCodeAt(0) - 65);
          let r = parseInt(cfg.address.replace(/\D/g, "")) - 1;
          if (
            r >= 0 &&
            r <= this?.control?.synopticComponent?.sheet.length - 1 &&
            c >= 0 &&
            c <= this?.control?.synopticComponent?.sheet[0].length - 1
          ) {
            target = {r: r, c: c};
          }
        }
      }
      return target;
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    dataIdList() {
      let lst = [];
      this.sheet.forEach((r) => {
        r.forEach((c) => {
          // let id = ((c?.data_id || "") + "").match(/\d+/g);
          let id = c?.data_id || "";
          if (id) {
            // lst.push(parseInt(id));
            lst.push(id);
          }
        });
      });
      return lst.filter((i, ix, a) => a.indexOf(i) == ix);
    },
    extendedDataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    dataList() {
      return this.extendedDataList.filter(
        ({id}) => this.dataIdList.indexOf(id) >= 0
      );
    },
    isSelected() {
      return (
        this.$parent.isSelected || this.$parent.$parent.isSelected || false
      ); // panel or synoptic control
    },
    isEditing() {
      // editing control is only TRUE after double clicking it.
      return this.$parent.isEditing || this.$parent.$parent.isEditing || false; // panel or synoptic control
    },
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    a1() {
      return this.address(this.active.r, this.active.c);
    },
    printPreview() {
      return this?.$store?.getters?.print || false;
    },
    globalFunctions() {
      return this.$store.getters["scripts/globalFunctions"];
    }
  },
  watch: {
    size(n, o) {
      if (
        n &&
        n.cols &&
        n.rows &&
        (!o || o.cols != n.cols || o.rows != n.rows)
      ) {
        this.onSheetResize();
      }
    },
    isSelected(n) {
      if (n && this.standAlone) {
        this.$nextTick(() => {
          let entry = {action: "sheet:activate"};
          if (this.$el.clientHeight < this.$el.firstChild.clientHeight) {
            entry.minHeight = this.$el.firstChild.clientHeight;
          }
          if (this.$el.clientWidth < this.$el.firstChild.clientWidth) {
            entry.minWidth = this.$el.firstChild.clientWidth;
          }
          this.trigger(entry);
        });
      }
    }
  },
  methods: {
    isEnabled(r, c) {
      if (!this.isEditing || (this.datasetTarget && r > this.datasetTarget.r))
        return false;
      return true;
    },
    allowInput(r, c) {
      return (
        this.inlineEditor &&
        !this.cellFormEditor &&
        this.isEnabled(r, r) &&
        (!this.datasetTarget || r < this.datasetTarget.r) &&
        !this.labeledSheet.rows[r][c].data_id &&
        (!this.labeledSheet.rows[r][c].data_source ||
          this.labeledSheet.rows[r][c].data_source == "constant")
      );
    },
    isCellActive(r, c) {
      return r == this.active.r && c == this.active.c;
    },
    setCellValue(r, c, value) {
      this.trigger({
        action: "cell:set_value",
        details: {
          row: r,
          column: c,
          value: value
        }
      });
    },
    value(r, c) {
      let vlr = "";
      let cell = this.labeledSheet.rows[r][c];
      let data = this.dataAt(r, c);
      if (data) {
        if ("current_value" in data && data.current_value) {
          if (cell.format) {
            vlr = this.$utils.sprintf(cell.format, data.current_value.value);
          } else {
            vlr = data.current_value.value;
          }
        }
      } else {
        if (
          cell.value != undefined &&
          !((cell.value + "").indexOf("${") >= 0)
        ) {
          if (!isNaN(Number(cell.value)) && cell.format) {
            vlr = this.$utils.sprintf(cell.format, cell.value);
          } else {
            vlr = cell.value;
          }
        }
      }
      if (vlr === "" && cell.default !== "") {
        if (this.datasetTarget && r >= this.datasetTarget.r) {
          return vlr;
        } else {
          vlr = cell.default;
        }
      }
      return vlr;
    },
    assignDataStats(data, expression) {
      if (!data) return;
      // expression: 'data.history.a_b_c[0].samples.length-data.history.d_e_f[1].samples.length'
      let re = expression.match(/\.history\.\w+\[\d+\]/g);
      if (re && re.length) {
        let entries = {};
        re.forEach((sre) => {
          let namedQuery = sre.replace(/(.history.|\[\d\])/g, "");
          if (namedQuery) {
            if (!entries[namedQuery]) {
              entries[namedQuery] =
                this.$store.getters[`${namedQuery}/aggregatedEntries`] || {};
            }
          }
        });
        data.history = structuredClone(entries);
      } else {
        if (data.id) {
          let entries = this.$store.getters["history/entries"] || {};
          if (entries[data.id]) {
            data.history = structuredClone(entries[data.id]);
          }
        }
      }
    },
    defaultDataCell(cell) {
      // system and simple expressions:
      let value = cell.value;
      let data = {
        ...structuredClone(this.$store.getters.systemProperties),
        template: value,
        default: cell.default
      };
      // the piece of code below allows the usage of any named query, and not only the one assigned to this table
      if (!cell.data_id && /\.history\..+\[\d\]/.test(value || "")) {
        this.assignDataStats(data, value);
      }
      if (cell.format == "text_list" && (cell?.stateList?.items || []).length) {
        let vlr = this.$root.$formatter.format(data);
        vlr = vlr === "" ? value : vlr;
        data = {
          current_value: {
            value: vlr
          },
          current_state: this.$root.$formatter.state(vlr, cell.stateList),
          template:
            "${data?.current_state?.label||data?.current_value?.value||'-'}"
        };
      } else {
        data.default =
          cell.default !== "" &&
          (cell.data_source == "system" || cell.data_source == "data")
            ? cell.default
            : data.template;
        data.template += cell.format ? "|" + cell.format : "";
      }
      return data;
    },
    dataAt(r, c) {
      let cell = this.labeledSheet.rows[r][c];
      let data = null;
      let value = cell.value;
      // indirect reference?
      // ros/cos = row and column offset
      let rc = null,
        ros = 0,
        cos = 0,
        vlr = "",
        indRef = `${value ?? ""}`.match(/R\[[-0-9]+\]C\[[-0-9]+\]/g);
      let refCell =
        (this.datasetTarget &&
          r >= this.datasetTarget.r &&
          this?.control?.synopticComponent?.sheet[this.datasetTarget.r][c]) ||
        null;
      if (indRef) {
        indRef.forEach((ref) => {
          rc = ref.match(/[-\d]+/g);
          if (rc.length == 2) {
            ros = parseInt(r) + parseInt(rc[0]);
            cos = parseInt(c) + parseInt(rc[1]);
            if (
              isNaN(ros) ||
              ros < 0 ||
              ros > this.labeledSheet.rows.length - 1
            )
              return;
            if (
              isNaN(cos) ||
              cos < 0 ||
              cos > this.labeledSheet.rows[ros].length - 1
            )
              return;
            if (ros == r && cos == c) return;
            rc = this.labeledSheet.rows[ros][cos];
            if (rc) {
              if (
                rc.data_id ||
                (rc.value !== "" && rc.value.indexOf("data.") >= 0)
              ) {
                var entry = this.dataAt(ros, cos);
                if (entry !== null) {
                  vlr = this.$root.$formatter.format(entry);
                }
              } else {
                vlr = this.labeledSheet.rows[ros][cos].value;
                vlr = vlr !== "" && isNaN(vlr) ? `"${vlr}"` : vlr;
              }
              value = value.replace(ref, vlr);
            }
          }
        });
      }
      if (cell.data_id) {
        if (this.datasetTarget && r >= this.datasetTarget.r) {
          data = {
            ...(refCell || cell),
            history: this.$store.getters["history/defHistoryData"]
          };
          // return refCell
          //   ? this.defaultDataCell(refCell)
          //   : this.defaultDataCell(cell);
          return data;
        }
        data = this.dataList.find((i) => i.id == cell.data_id) || null;
        if (data) {
          data = JSON.parse(JSON.stringify(data));
          if (
            data?.device?.connector?.id > 0 &&
            !("description" in (data?.device?.connector || {}))
          ) {
            let connector = this.$store.getters["dashboard/connectorList"].find(
              ({id}) => id == data.device.connector.id
            );
            if (connector) {
              data.device.connector = {
                ...data.device.connector,
                ...connector
              };
            }
          }
          this.assignDataStats(data, value);
          if (
            cell.format == "text_list" &&
            (cell?.stateList?.items || []).length
          ) {
            let vlr = this.$root.$formatter.format({
              ...data,
              template: value === "" ? "${data?.current_value?.value}" : value,
              default: ""
            });
            vlr = vlr === "" ? value : vlr;
            data = {
              current_value: {
                value: vlr
              },
              current_state: this.$root.$formatter.state(vlr, cell.stateList),
              template:
                "${data?.current_state?.label||data?.current_value?.value||'-'}"
            };
          } else {
            data.text_list = null; // important! otherwise it would handle it if data also defines a text-list
            if (cell.default !== "") {
              data.default = cell.default;
            }
            currentValueTypeCast(data);
            if (value && value !== "data") {
              data.template = value;
            } else {
              data.template = "${data?.current_value?.value}";
            }
            data.template += cell.format ? "|" + cell.format : "";
          }
          return data;
        }
      } else if (this.datasetTarget && r >= this.datasetTarget.r) {
        if (refCell && refCell.format == "text_list") {
          if ((refCell.stateList?.items || []).length) {
            data = {
              current_value: {
                value: value
              },
              current_state: this.$root.$formatter.state(
                value,
                refCell.stateList
              ),
              template:
                "${data?.current_state?.label||data?.current_value?.value||'-'}"
            };
          }
          return data;
        }
        return {
          value: this.dataTable ? value : "",
          template: "${data.value}" + (cell.format ? "|" + cell.format : ""),
          default: cell.default
        };
      } else {
        return this.defaultDataCell({...cell, value: value});
      }
      return null;
    },
    cellState(r, c) {
      let cell = null;
      if (this.datasetTarget && r >= this.datasetTarget.r) {
        cell = this?.control?.synopticComponent?.sheet[this.datasetTarget.r][c];
      } else {
        cell = this.labeledSheet.rows[r][c];
      }
      if (
        cell &&
        cell.format == "text_list" &&
        (cell?.stateList?.items || []).length
      ) {
        return this.dataAt(r, c)?.current_state ?? null;
      }
      return null;
    },
    cellStyle(pageOffset, row, col) {
      let c = col;
      let r = pageOffset + row;
      let cell = this.labeledSheet.rows[r][c];
      if (cell) {
        let style = JSON.parse(JSON.stringify((cell && cell.style) || {}));
        if (cell?.textAlign) {
          style["text-align"] = cell.textAlign;
        } else {
          if (!("text-align" in style)) {
            style["text-align"] = "center";
          }
        }
        delete style["padding"]; // it is applied by the cell content method
        // apply any state css (not persistent css)
        let state; // = this.cellState(r, c) || null;
        if (pageOffset && this.datasetTarget && row < this.datasetTarget.r) {
          state = this.cellState(row, c) || null;
        } else {
          state = this.cellState(r, c) || null;
        }
        if (state) {
          style["background-color"] = state.backgroundColor;
        }
        if ("white-space" in style && this.printPreview) {
          delete style["white-space"]; // treated by default printout cell class
        }
        return style;
      }
      return {};
    },
    cellContentStyle(r, c) {
      let cell = this.labeledSheet.rows[r][c];
      let style = {
        padding: cell?.style?.padding || "0"
      };
      return style;
    },
    cellClass(r, c) {
      if (this.isEditing) {
        if (this.active.r == r && this.active.c == c) {
          return "cell-edit active-cell";
        }
        return "cell-edit";
      }
      return "";
    },
    address(r, c) {
      let char = c <= 90 ? String.fromCharCode(c + 65) : "!"; // 90=Z and it is a too big table
      return `${char}${r + 1}`;
    },
    onMouseDown($event) {
      if (this.isEditing) {
        $event.stopPropagation();
      }
    },
    onCellClicked(r, c) {
      if (this.isEditing) {
        if (!this.isEnabled(r, c)) return;
        this.active.r = r;
        this.active.c = c;
        this.trigger({
          action: "cell:activate",
          details: {
            row: r,
            column: c
          }
        });
      }
    },
    onCellMenuClicked($event, ir, ic, option) {
      if (!this.isEditing) return;
      const r = ir !== null ? ir : this.active.r;
      const c = ic !== null ? ic : this.active.c;
      if (!option && !this.isEnabled(r, c)) return;
      if (option) {
        if (option == "cell:edit" && this.$refs.menu) {
          this.$refs.menu.close();
        }
        this.trigger({
          action: option,
          details: {
            row: this.active.r,
            column: this.active.c
          }
        });
        if (
          option == "column:del" &&
          this.active.c > 0 &&
          this.active.c == this.nColumns - 1
        )
          this.active.c--;
        else if (
          option == "row:del" &&
          this.active.r > 0 &&
          this.active.r == this.nRows - 1
        )
          this.active.r--;
      } else {
        this.onCellClicked(r, c);
        if (this.$refs.menu) {
          this.$refs.menu.close();
          this.menu = {
            sx: window.scrollX,
            sy: window.scrollY,
            oy:
              window.innerHeight - $event.pageY > 0
                ? 0
                : window.innerHeight - $event.pageY
          };
          let pos = {
            clientY: $event.clientY + window.scrollY,
            clientX: $event.clientX
          };
          this.$nextTick(() => {
            if (this.$refs.menu) {
              this.$refs.menu.open(pos);
            }
          });
        }
      }
    },
    onContextMenu() {
      this.$root.$emit("controlSidebar:collapse", false);
      window.scrollTo(this.menu.sx, this.menu.sy);
    },
    onKeyUp($event) {
      if (!this.isEditing) return;
      let r = this.active.r;
      let c = this.active.c;
      let inp;
      if (!$event || $event.target.nodeName != "DIV") return;
      switch ($event.code) {
        case "ArrowDown":
          r += 1;
          if (r > this.sheet.length - 1) r = this.sheet.length - 1;
          break;
        case "ArrowUp":
          r -= 1;
          if (r < 0) r = 0;
          break;
        case "ArrowLeft":
          c -= 1;
          if (c < 0) c = 0;
          break;
        case "ArrowRight":
          c += 1;
          if (c > this.sheet[0].length - 1) c = this.sheet[0].length - 1;
          break;
        case "Enter":
        case "NumpadEnter":
          this.setInputFocus(r, c);
          return;
        case "Delete":
          inp = this.setInputFocus(r, c);
          if (inp) {
            this.setCellValue(r, c, "");
          }
          break;
        default:
          inp = this.setInputFocus(r, c);
          if (inp && $event.key && $event.key.length == 1) {
            inp.value += $event.key;
          }
      }
      this.onCellClicked(r, c);
    },
    setInputFocus(r, c) {
      if (this.$refs.sheet) {
        let els = this.$refs.sheet.children[r].children[c].getElementsByTagName(
          "INPUT"
        );
        if (els && els.length) {
          els[0].focus();
          return els[0];
        }
      }
      return null;
    },
    onMouseDownDragColumn(e, r, c) {
      e.stopPropagation();
      this.dragging.c = c;
      this.dragging.r = r;
      this.dragging.w = 0;
    },
    onMouseUpDragColumn(e, r, c) {
      e.stopPropagation();
      if (
        this.dragging.c == c &&
        this.dragging.r == r &&
        this.dragging.w == 0
      ) {
        this.dragging.c = -1;
        this.dragging.r = -1;
        this.dragging.w = -1;
      }
    },
    startDragColumn(e, r, c) {
      this.dragging.c = c;
      this.dragging.r = r;
      this.dragging.w = 0;
      e.dataTransfer.setDragImage(this.DragImage, 0, 0);
      // e.dataTransfer.setDragImage(new Image(), 0, 0);
      e.dataTransfer.effectAllowed = "move";
      this.eCel = e.target.parentNode;
      this.xIni = e.pageX || e.clientX;
      this.wIni = this.eCel.getBoundingClientRect().width;
      this.wPrv = undefined;
    },
    dragOverColumn(e) {
      e.preventDefault();
      e.stopPropagation();
      if (!this.eCel || !this.xIni || !this.dragging.c < 0) return;
      this.dragging.w = (e.pageX || e.clientX) - this.xIni;
    },
    stopDragColumn(e) {
      e.preventDefault();
      if (!this.eCel || !this.xIni || !this.dragging.c < 0) return;
      let total = this.eCel.parentNode.parentNode.getBoundingClientRect().width;
      let w = Math.round(((this.wIni + this.dragging.w) / total) * 100);
      if (w < 10) w = 10;
      if (this.wPrv != w) {
        this.wPrv = w;
        this.trigger({
          action: "column:set_width",
          details: {
            row: this.dragging.r,
            column: this.dragging.c,
            width: w
          }
        });
      }
      this.dragging.c = -1;
      this.dragging.r = -1;
      this.dragging.w = -1;
    },
    trigger(ev) {
      if (this.standAlone) {
        ev.details = ev.details || {};
        if (!ev.details.control) {
          ev.details.control = this.control.synopticComponent;
        }
        this.$root.$emit("table:event", ev);
      } else {
        this.$emit("tableEvent", ev);
      }
    },
    onClick($event, opt) {
      if (this.isEditing && this.standAlone) {
        // Just active A0 cell, since it has already been activated by isSelected watcher
        let $tbl = document.getElementById(this.tableId);
        let x = 0,
          y = 0;
        if ($event && $tbl) {
          let $td = (
            document.elementsFromPoint($event.clientX, $event.clientY) || []
          ).find((i) => i.nodeName == "TD");
          if ($td) {
            for (var i = 0, row; (row = ($tbl?.rows || [])[i]); i++) {
              for (var j = 0, td; (td = (row?.cells || [])[j]); j++) {
                if ($td == td) {
                  x = i;
                  y = j;
                  break;
                }
              }
            }
          }
        }
        this.onCellClicked(x, y);
      }
    },
    onSheetResize() {
      this.$root.$emit("panel:resized");
      if (this.standAlone) {
        this.$nextTick(() => {
          let rect = this.tableRect();
          if (rect) {
            this.trigger({
              action: "sheet:client_rect",
              details: {
                width: rect.width,
                height: rect.height
              }
            });
          }
        });
      }
    },
    rectError() {
      if (this.mode != "editor") return false;
      let rect = this.tableRect();
      if (this.standAlone && rect) {
        return (
          this?.control?.synopticComponent?.clientRect?.width <
            Math.round(rect.width) ||
          this?.control?.synopticComponent?.clientRect?.height <
            Math.round(rect.height)
        );
      }
      return false;
    },
    normalizeRow(row, page) {
      // returns original header row if it's equivalent
      return row < this.datasetTarget?.r ? row : row + page.offset;
    },
    tableRect() {
      if (this?.$refs?.table?.length) {
        this.$refs.table[0].getBoundingClientRect();
      } else if (this?.$refs?.table) {
        this.$refs.table.getBoundingClientRect();
      } else {
        return null;
      }
    },
    onDownload(filetype) {
      if (!this.datasetTarget || !this.dataset) return;
      let fname = `dataset-${new Date()
        .toISOString()
        .replace(/[\-\:T]/g, "")
        .split(".")[0]
        .substr(2)}`;
      switch (filetype) {
        case "csv":
          this.$utils.HTMLTable2CSV(
            document.getElementById(this.tableId),
            fname,
            () => {
              this.$emit("done");
            },
            false
          );
          break;
        case "xls":
          this.$utils.HTMLTable2XLS(
            document.getElementById(this.tableId),
            fname,
            () => {
              this.$emit("done");
            },
            false
          );
          break;
      }
    },
    onCellFormVisible(n) {
      this.cellFormEditor = n;
    }
  },
  mounted() {
    this.tableId = `${this.$options.name}${this._uid}`;
    this.$emit("tableId", this.tableId);
    this.$emit("hasContent", true);
    this.$nextTick(() => {
      this.errorCheck += 1;
    });
    if (this.pageHeight) {
      this.rowHeight =
        this.$refs.table[0].querySelector(
          `tr:nth-child(${(this.datasetTarget?.r ?? 0) + 1})`
        ).clientHeight * (this.printScale ?? 1);
    }
  },
  beforeCreate() {
    this.DragImage = new Image();
    this.DragImage.src =
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2\
ZyB3aWR0aD0iMSIgaGVpZ2h0PSIxIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxIDEiIHht\
bG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3Lncz\
Lm9yZy8yMDAwL3N2ZyI+PC9zdmc+Cg==";
  },
  created() {
    this.eCel = undefined; // not reactive
    this.xIni = undefined; // not reactive
    this.wIni = undefined; // not reactive
    this.wPrv = undefined; // not reactive
    if (this.datasetTarget && (this.datasetIdList || []).length) {
      this.$root.$on("toolbar:download", this.onDownload);
    }
    this.$root.$on("cellform:visible", this.onCellFormVisible);
  },
  beforeDestroy() {
    this.DragImage = null;
    this.$root.$off("toolbar:download", this.onDownload);
    this.$root.$off("cellform:visible", this.onCellFormVisible);
  }
};
</script>

<style scoped>
.me {
  width: 100%;
  vertical-align: middle;
  height: 100%;
}

tr,
th,
td {
  page-break-inside: avoid;
}

.display-table {
  table-layout: fixed;
  margin: 0;
  height: 100%;
  width: 100%;
  -webkit-text-size-adjust: var(--text-size-adjust);
  -moz-text-size-adjust: var(--text-size-adjust);
  text-size-adjust: var(--text-size-adjust);
}

.display-table > thead > tr,
.display-table > tbody > tr,
.display-table > tfoot > tr,
.display-table > thead > tr,
.display-table > tbody > tr,
.display-table > tfoot > tr {
  height: 22px;
}

.display-table > thead > tr > th,
.display-table > tbody > tr > th,
.display-table > tfoot > tr > th,
.display-table > thead > tr > td,
.display-table > tbody > tr > td,
.display-table > tfoot > tr > td {
  position: relative;
  margin: 0;
  border-color: var(--border-color);
  border-width: var(--border-width);
  border-style: var(--border-style);
  overflow: hidden;
}

.display-table > tbody > tr > td {
  transition: background-color 0.5s ease;
  vertical-align: middle;
}

.table-error > thead > tr > th,
.table-error > tbody > tr > th,
.table-error > tfoot > tr > th,
.table-error > thead > tr > td,
.table-error > tbody > tr > td,
.table-error > tfoot > tr > td {
  background-color: #ff000008 !important;
  transition: none;
}

.print-preview > thead > tr > th,
.print-preview > tbody > tr > th,
.print-preview > tfoot > tr > th,
.print-preview > thead > tr > td,
.print-preview > tbody > tr > td,
.print-preview > tfoot > tr > td {
  white-space: nowrap;
  overflow: hidden;
}

.cell {
  border: none;
  outline: none;
}

.cell:focus {
  border: none;
  outline: none;
}

/* edit mode */
.cell-edit {
  outline-offset: -2px;
  outline: 1px groove transparent;
}

.cell-edit:hover {
  /* cursor: pointer;
  opacity: 0.8; */
}

.active-cell,
.active-cell:hover {
  outline-color: rgb(78, 145, 221);
  /* border-color: transparent !important; */
  /* z-index: 1; */
}

.cell-separator {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 4px;
  right: 0;
  border-width: 0 1px 0 1px;
  border-style: none;
  border-color: darkgray;
  border-radius: 3px;
  cursor: col-resize;
  z-index: 1;
}

.cell-edit:hover > .cell-separator,
.cell-edit > .cell-separator.column-dragging {
  border-style: double;
  /* z-index: 3; */
}

.contextmenu {
  position: absolute;
  max-height: initial;
  padding: 10px 15px;
  border: 1px solid #999;
  border-radius: 0.4rem;
  font-size: 14px;
  text-align: left;
  background-color: white;
  z-index: 100;
}

.contextmenu > li {
  cursor: pointer;
  list-style: none;
}

.contextmenu > li:hover {
  background-color: #aaa;
}

.contextmenu > li.divider {
  margin: 12px -15px;
  height: 1px;
  border-top: 1px solid #aaa;
}

.contextmenu > li > a {
  color: #333;
  text-align: left;
  padding: 2px;
  font-size: 1.2rem;
}

.contextmenu > li > a:hover {
  cursor: pointer;
  opacity: 0.8;
}

.contextmenu > li > a > i {
  font-size: 1.168em;
  margin-right: 1rem;
  margin-left: 0rem;
}

.contextmenu > li.contextmenu-info {
  position: absolute;
  top: 0;
  background-color: transparent;
  color: #000;
  z-index: 101;
  font-size: 75%;
  padding: 2px 4px;
}

.contextmenu > li.contextmenu-info:hover {
  background-color: transparent !important;
  opacity: 0.8;
  color: #a95c44;
}

.contextmenu > li.address {
  left: 2px;
  color: #a95c44;
}

.contextmenu > li.close {
  right: 0px;
  top: 2px;
}

.contextmenu > li.gap {
  margin: 8px -15px 2px -15px;
  height: 0;
  padding: 0;
  border-bottom: 1px solid rgb(206, 206, 206);
}

.hide {
  background-color: red;
}
</style>

<style>
.page-break {
  page-break-before: always;
}
</style>
